import { gql, TypedDocumentNode } from '@apollo/client'
import JobCategoryType from '../types/job-category-type'

export const GET_JOB_CATEGORIES: TypedDocumentNode<{ jobCategories: JobCategoryType[] }, { q: string }> = gql`
  query GetJobCategories($q: String) {
    jobCategories(q: $q) {
      id
      slug
      name
      description
    }
  }
`

export const GET_JOB_CATEGORY: TypedDocumentNode<{ jobCategory: JobCategoryType }, { id?: string, slug?: string }> = gql`
  query GetJobCategory($id: ID, $slug: String) {
    jobCategory(id: $id, slug: $slug) {
      id
      slug
      name
      description
    }
  }
`
