//@ts-expect-error: This is needed by Rails
import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import CategoryCard from '../../components/CategoryCard'
import { useSuspenseQuery } from '@apollo/client'
import { GET_JOB_CATEGORIES } from '../../graphql/job-category-queries'
import JobCategoryType from '../../types/job-category-type'
import CityType from '../../types/city-type'
import { useNavigate } from 'react-router-dom'
import { CustomerContext } from '../../layouts/customer/CustomerContextProvider'
import LocationAutocomplete from '../../components/LocationAutocomplete'
import JobCategoryAutocomplete from '../../components/JobCategoryAutocomplete'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'

type JobCategoriesProps = {
  selectedJobCategory: JobCategoryType | null,
  onClick: (jobCategory: JobCategoryType | null) => void
}

function JobCategories({ selectedJobCategory, onClick }: JobCategoriesProps) {
  const { data } = useSuspenseQuery(GET_JOB_CATEGORIES)

  return (
    <Grid container spacing={4} sx={{ mt: '50px' }}>
      {data.jobCategories.map((jobCategory) => (
        <Grid xs={12} sm={6} md={3} key={jobCategory.id}>
          <CategoryCard
            jobCategory={jobCategory}
            selected={jobCategory.id === selectedJobCategory?.id}
            onClick={onClick}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default function GetEstimatesPage() {
  const navigate = useNavigate()
  const currentCustomer = useContext(CustomerContext)

  const [city, setCity] = useState<CityType | null>(currentCustomer?.lastCity || null)
  const [jobCategory, setJobCategory] = useState<JobCategoryType | null>(null)

  useEffect(() => {
    if (currentCustomer) {
      setCity(currentCustomer.lastCity)
    }
  }, [currentCustomer])

  function navigateToNewJobPage(jobCategorySlug = jobCategory?.slug) {
    navigate(`/customer/new_job/${jobCategorySlug}/location/${city?.id}`)
  }

  function handleJobCategoryClick(jobCategory: JobCategoryType | null) {
    if (city) {
      navigateToNewJobPage(jobCategory?.slug)
    } else {
      setJobCategory(jobCategory)
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12} sx={{ mb: '30px' }}>
          <Typography level="h1" sx={{ mt: '15px', display: 'flex', alignItems: 'center' }}>
            <PersonSearchIcon sx={{ fontSize: '35px', mr: '3px' }}/>
            Get Estimates
          </Typography>
        </Grid>
        <Grid xs={12} sm={3} md={3}>
          <FormControl>
            <FormLabel>Location</FormLabel>
            <LocationAutocomplete
              onValueChange={(value) => setCity(value as CityType)}
              value={city}
              autoFocus={!city?.id ? true : undefined}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={5} md={4}>
          <FormControl>
            <FormLabel>Category</FormLabel>
            <JobCategoryAutocomplete
              onValueChange={(value) => setJobCategory(value as JobCategoryType)}
              value={jobCategory}
              autoFocus={city?.id && !jobCategory?.id ? true : undefined}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={2} md={3}>
          <Button
            sx={{ mt: '25px' }}
            onClick={() => navigateToNewJobPage()}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
      <JobCategories selectedJobCategory={jobCategory} onClick={handleJobCategoryClick}/>
    </>
  )
}
