//@ts-expect-error: This is needed by Rails
import React, { ReactNode, createContext, useState } from 'react'

interface LayoutContextType {
  openAlert: boolean
  setOpenAlert: (val: LayoutContextType['openAlert']) => void
  alertMessage: string | null
  setAlertMessage: (val: LayoutContextType['alertMessage']) => void
  alertType: 'success' | 'danger'
  showAlert: (message: string, type?: LayoutContextType['alertType']) => void
  environment: string
}

export const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType)

export default function LayoutContextProvider({ children }: { children: ReactNode }) {
  const [openAlert, setOpenAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState<string | null>(null)
  const [alertType, setAlertType] = useState<LayoutContextType['alertType']>('success')
  // TODO: Move this to something like AppContext
  const environment = document.getElementById('react_app')?.dataset['env']

  const context: LayoutContextType = {
    openAlert,
    setOpenAlert,
    alertMessage,
    alertType,
    setAlertMessage,
    showAlert: (message: string, type: LayoutContextType['alertType'] = 'success') => {
      setAlertType(type)
      setAlertMessage(message)
      setOpenAlert(true)
    },
    environment: environment!
  }
  return <LayoutContext.Provider value={context}>{children}</LayoutContext.Provider>
}
