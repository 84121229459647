//@ts-expect-error: This is needed by Rails
import React, { Fragment } from 'react'
import Typography from '@mui/joy/Typography'
import { GET_LEADS } from '../../graphql/job-queries'
import JobType from '../../types/job-type'
import { useSuspenseQuery } from '@apollo/client'
import Grid from '@mui/joy/Grid'
import LeadCard from '../../components/LeadCard'
import { GET_BUSINESS_PROFILE } from '../../graphql/business-profile-queries'
import { Link } from 'react-router-dom'

export default function LeadsPage() {
  const { data } = useSuspenseQuery(GET_LEADS)
  const { data: { businessProfile: { completed: completedProfile } } } = useSuspenseQuery(GET_BUSINESS_PROFILE)

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1">Leads</Typography>
      </Grid>
      {completedProfile ?
        data.leads.map((job: JobType) => {
          return (
            <Fragment key={job.id}>
              <Grid xs={12} sm={8} md={6}>
                <LeadCard job={job} />
              </Grid>
              <Grid md={6} />
            </Fragment>
          )
        }) :
        <Grid xs={12} sx={{ mt: '20px' }}>Please complete your <Link to="/business/profile">profile</Link> to see leads</Grid>}
    </Grid>
  )
}
