import React, { ReactNode, useState } from 'react'
import { Grid, FormLabel, FormControl, Input, Textarea, Button, Select, Option, RadioGroup, Radio, Sheet } from '@mui/joy'
import { JobAttributes, JobTimeline } from '../types/job-type'
import { colors } from '../layouts/Theme'
import NumericFormatAdapter from './NumericFormatAdapter'
import { Controller, useForm } from 'react-hook-form'
import ImageUploadPreview from './ImageUploadPreview'
import ImageUploadButton from './ImageUploadButton'
import { fileListFromArray } from '../utils/general-utils'

type BudgetType = 'withoutBudget' | 'withBudget'

interface Props {
  onSubmit: (data: JobAttributes) => void
  saving: boolean
  submitLabel: string
  timeline?: JobTimeline
  budgetType?: BudgetType
  budgetMin?: number
  budgetMax?: number
  description?: string
  buttonSlot?: ReactNode
}

export default function JobForm({ 
  onSubmit, 
  saving = false, 
  submitLabel,
  timeline = JobTimeline.asap,
  budgetType: initialBudgetType = 'withoutBudget',
  budgetMin,
  budgetMax,
  description,
  buttonSlot,
}: Props) {

  const [imagePreviews, setImagePreviews] = useState<string[]>([])
  const [budgetType, setBudgetType] = useState<BudgetType>(initialBudgetType)
  const { register, handleSubmit, control, getValues, setValue } = useForm<JobAttributes>({
    defaultValues: {
      description,
    }
  })

  function removeImage(index: number) {
    const currentFiles = getValues('fileList')
    if (!currentFiles) return

    const newImagePreviews = imagePreviews.filter((_, i) => i !== index)
    const newFiles = Array.from(currentFiles).filter((_, i) => i !== index)

    setValue('fileList', fileListFromArray(newFiles))
    setImagePreviews(newImagePreviews)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} md={3}>
          <FormControl>
            <FormLabel>Timeline</FormLabel>
            <Controller
              name="timeline"
              control={control}
              defaultValue={timeline}
              render={({ field: { onChange } }) => (
                <Select
                  value={timeline}
                  onChange={(_e, val) => onChange(val)}
                >
                  <Option value={JobTimeline.asap}>As soon as possible</Option>
                  <Option value={JobTimeline.inWeeks}>1-3 weeks from now</Option>
                  <Option value={JobTimeline.inMonths}>1-3 months from now</Option>
                  <Option value={JobTimeline.inHalfYear}>6-12 months from now</Option>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid md={9}/>
        <Grid xs={12}>
          <FormLabel sx={{ mb: 1 }}>
            Budget Option
          </FormLabel>
          <RadioGroup
            orientation="horizontal"
            value={budgetType}
            onChange={({ target: { value }}) => setBudgetType(value as BudgetType)}
            sx={{ gap: 1.5 }}
          >
            {["withoutBudget", "withBudget"].map((value) => (
              <Sheet
                key={value}
                sx={{
                  p: '10px 15px',
                  borderRadius: 'md',
                  width: {
                    xs: '100%',
                    sm: '50%',
                    md: '30%',
                  }
                }}
              >
                <Radio
                  overlay
                  disableIcon
                  label={value === 'withBudget' ? 'I have a budget in mind' : "I don't have a budget in mind"}
                  value={value}
                  slotProps={{
                    label: ({ checked }) => ({
                      sx: {
                        fontWeight: checked ? 500 : 'initial',
                        color: checked ? colors.primary : colors.fontColor
                      }
                    }),
                    action: ({ checked }) => ({
                      sx: {

                        '&:hover': {
                          borderColor: colors.primary,
                          backgroundColor: '#FFF',
                        },
                        ...(checked && {
                          inset: -1,
                          '--variant-borderWidth': '1px',
                          '&&': {
                            borderColor: colors.primary,
                          }
                        })
                      }
                    })
                  }}
                />
              </Sheet>
            ))}
          </RadioGroup>
        </Grid>
        {budgetType === "withBudget" &&
          <>
            <Grid xs={12} sm={6} md={3}>
              <FormControl>
                <FormLabel>Lower range</FormLabel>
                <Controller
                  name="budgetMin"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Input
                      startDecorator="₱"
                      defaultValue={budgetMin}
                      onChange={(e) => onChange(+e.target.value)}
                      slotProps={{
                        input: {
                          component: NumericFormatAdapter,
                        },
                      }}
                      placeholder="1,000"
                      autoFocus
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <FormControl>
                <FormLabel>Higher range</FormLabel>
                <Controller
                  name="budgetMax"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Input
                      defaultValue={budgetMax}
                      startDecorator="₱"
                      onChange={(e) => onChange(+e.target.value)}
                      slotProps={{
                        input: {
                          component: NumericFormatAdapter,
                        },
                      }}
                      placeholder="1,000"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </>}
        <Grid xs={12} sm={9}>
          <ImageUploadButton setImagePreviews={setImagePreviews} onChange={(files) => setValue('fileList', fileListFromArray(files))} />
          <ImageUploadPreview imagePreviews={imagePreviews} onRemove={removeImage} />
        </Grid>
        <Grid xs={12} sm={9}>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea autoFocus {...register("description")} placeholder="Describe your job in details here" minRows={6}/>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Button onClick={handleSubmit(onSubmit)} disabled={saving} sx={{ mr: '10px' }}>{submitLabel}</Button>
          {buttonSlot}
        </Grid>
      </Grid>
    </form>
  )
}
