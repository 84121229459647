//@ts-expect-error: This is needed by Rails
import React, { Suspense } from 'react'
import Box from '@mui/joy/Box'
import { Outlet } from 'react-router-dom'
import BusinessSidebar from './Sidebar'
import { colors } from '../Theme'
import LayoutContextProvider from '../LayoutContextProvider'
import Alert from '../Alert'
import Header from '../Header'
import BusinessUserContextProvider from './BusinessUserContextProvider'

export default function BusinessLayout() {
  return (
    <LayoutContextProvider>
      <BusinessUserContextProvider>
        <Box sx={{ display: 'flex' }}>
          <BusinessSidebar />
          <Box
            sx={{
              width: '100%',
              paddingTop: { xs: '0', sm: '10px' },
              backgroundColor: colors.background,
            }}
          >
            <Header />
            <Box sx={{ p: { xs: '20px', sm: '30px' }}}>
              <Alert />
              <Suspense>
                <Outlet />
              </Suspense>
            </Box>
          </Box>
        </Box>
      </BusinessUserContextProvider>
    </LayoutContextProvider>
  )
}
