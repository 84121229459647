//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box, IconButton } from '@mui/joy'
import CancelIcon from '@mui/icons-material/Cancel'

export default function ImageUploadPreview({ imagePreviews, onRemove }: { imagePreviews: string[], onRemove: (index: number) => void }) {
  return (
    !!imagePreviews.length &&
      <Box sx={{ display: 'flex', mt: '10px', gap: '10px' }}>
        {imagePreviews.map((previewURL, index) => {
          return (
            <Box key={index} sx={{ position: 'relative' }}>
              <IconButton
                onClick={() => onRemove(index)}
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  minHeight: '20px',
                  height: '20px',
                  minWidth: '20px',
                  width: '20px',
                  zIndex: 10,
                  backgroundColor: 'white',
                  borderRadius: '50%'
                }}
              >
                <CancelIcon fontSize="small"/>
              </IconButton>
              <img src={previewURL} width="100px" />
            </Box>
          )
        })}
      </Box>
  )
}
