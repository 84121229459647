import { gql, TypedDocumentNode } from '@apollo/client'
import CityType from '../types/city-type'

export const GET_CITIES: TypedDocumentNode<{ cities: CityType[] }, { q: string }> = gql`
  query GetCities($q: String) {
    cities(q: $q) {
      id
      name
      provinceId
      provinceName
    }
  }
`

export const GET_CITY: TypedDocumentNode<{ city: CityType }, { id: string }> = gql`
  query GetCity($id: ID!) {
    city(id: $id) {
      id
      name
      provinceId
      provinceName
    }
  }
`
