import { TypedDocumentNode, gql } from '@apollo/client'
import Project from '../types/project-type'

export const GET_PROJECTS: TypedDocumentNode<{ projects: Project[] }> = gql`
  query GetProjects {
    projects {
      id
      name
      description
      images {
        id
        url
        thumbnail
        width
        height
      }
    }
  }
`

export const CREATE_PROJECT: TypedDocumentNode<{ errors: string[], job: Project }, { name: string, description: string, fileList?: FileList }> = gql`
  mutation CreateProject($name: String!, $description: String!, $fileList: [Upload!]) {
    createProject(input: { name: $name, description: $description, fileList: $fileList }) {
      errors
      project {
        id
        name
        description
        images {
          id
          url
          thumbnail
          width
          height
        }
      }
    }
  }
`
