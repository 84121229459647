import React from "react"
import Box from '@mui/joy/Box'
import { colors } from '../layouts/Theme'
import { IconButton } from "@mui/joy"
import { closeSidebar } from "../utils/nav-utils"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

export const listStyle = {
  '.MuiListItem-root': {
    mb: '10px',
  },
  '.MuiListItem-root .MuiListItemButton-root': {
    color: colors.fontColor,
    fontWeight: 500,
  },
  '.MuiListItem-root .MuiListItemButton-root:hover, .MuiListItem-root .MuiListItemButton-root.Mui-selected': {
    backgroundColor: 'transparent',
    color: colors.primary,
  },
  '.MuiListItem-root .MuiListItemButton-root.Mui-selected': {
    fontWeight: 700,
  },
  '.MuiListDivider-root': {
    backgroundColor: colors.gray1
  }
}

export default function Sidebar({ children }: { children: React.ReactNode }) {
  return (
    <Box>
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box
        sx={{
          display: 'flex',
          height: '100dvh',
          padding: { xs: '20px 15px 0', sm: '30px 20px 0'},
          position: { xs: 'fixed', sm: 'sticky' },
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
            sm: 'none',
          },
          zIndex: 999,
          transition: 'transform 0.4s, width 0.4s',
          width: { xs: '200px', sm: '240px'},
          top: 0,
          left: 0,
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: { xs: 'white', sm: colors.background },
          borderRight: `1px solid ${colors.gray1}`,
        }}
      >
        <Box sx={{ '& img': { width: '150px', display: { xs: 'none', sm: 'flex' } }}}>
          <img src="/logo.png" style={{ marginLeft: '15px' }} />
          <IconButton
            variant="outlined"
            color="neutral"
            size="sm"
            sx={{ display: { xs: 'flex', sm: 'none' } }}
            onClick={() => closeSidebar()}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          {children}
        </Box>
      </Box>
    </Box>
  )
}
