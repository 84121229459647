//@ts-expect-error: This is needed by Rails
import React from "react"
import { createBrowserRouter } from 'react-router-dom'
import LeadsPage from "./pages/business/LeadsPage"
import EstimatesPage from "./pages/business/EstimatesPage"
import ProfilePage from "./pages/business/ProfilePage"
import BusinessLayout from "./layouts/business/Layout"
import CustomerLayout from "./layouts/customer/Layout"
import GetEstimatesPage from "./pages/customer/GetEstimatesPage"
import JobsPage from "./pages/customer/JobsPage"
import NewJobPage from "./pages/customer/NewJobPage"
import JobPage from './pages/customer/JobPage'
import ViewEstimatePage from "./pages/customer/ViewEstimatePage"
import CustomerNotificationsPage from "./pages/customer/NotificationsPage"
import BusinessNotificationsPage from "./pages/business/NotificationsPage"
import LeadPage from "./pages/business/LeadPage"

export const router = createBrowserRouter([
  {
    element: <BusinessLayout />,
    children: [
      {
        path: '/business/notifications',
        element: <BusinessNotificationsPage />
      },
      {
        path: '/business/leads',
        element: <LeadsPage />
      },
      {
        path: '/business/leads/:jobId',
        element: <LeadPage />
      },
      {
        path: '/business/estimates',
        element: <EstimatesPage />
      },
      {
        path: '/business/profile',
        element: <ProfilePage />
      },
    ]
  },
  {
    element: <CustomerLayout />,
    children: [
      {
        path: '/customer/notifications',
        element: <CustomerNotificationsPage />
      },
      {
        path: '/customer/get-estimates',
        element: <GetEstimatesPage />
      },
      {
        path: '/customer/jobs',
        element: <JobsPage />
      },
      {
        path: '/customer/job/:jobId',
        element: <JobPage />
      },
      {
        path: '/customer/job/:jobId/estimate/:estimateId',
        element: <ViewEstimatePage />
      },
      {
        path: '/customer/new_job/:categorySlug/location/:locationId',
        element: <NewJobPage />
      },
    ]
  }
])
