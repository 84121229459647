//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box, Grid, Typography } from '@mui/joy'
import Notification from '../types/notification-type'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import CircleIcon from '@mui/icons-material/Circle'
import { colors } from '../layouts/Theme'
import { useMutation } from '@apollo/client'
import { MARK_NOTIFICATION_AS_READ } from '../graphql/notification-queries'
import { GET_CURRENT_BUSINESS_USER, GET_CURRENT_CUSTOMER } from '../graphql/user-queries'

export default function NotificationsList({ notifications, type }: { notifications: Notification[], type: 'customer' | 'business' }) {
  const navigate = useNavigate()
  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ)

  function onNotificationClick(id:string, path: string) {
    markNotificationAsRead({
      variables: { id },
      refetchQueries: [{ query: (type === 'customer' ? GET_CURRENT_CUSTOMER : GET_CURRENT_BUSINESS_USER) }]
    })
    navigate(path)
  }

  return (
    notifications.map(({ id, subject, path, isRead, createdAt }) => (
      <Grid xs={12} key={id}>
        <Box
          onClick={() => onNotificationClick(id, path)}
          sx={{
            '&:hover': { cursor: 'pointer' },
            backgroundColor: 'white',
            padding: '12px 16px',
            borderRadius: '8px',
            display: 'flex',
            gap: 2,
            width: { xs: '100%', sm: '50%', md: '40%' }
          }}
        >
          <Box sx={{ width: '60px', height: '60px', borderRadius: '50%', backgroundColor: colors.gray1 }}></Box>
          <Box sx={{ width: '70%' }}>
            <Typography level="title-md">{subject}</Typography>
            <Typography level="body-sm">{dayjs(createdAt).fromNow()}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            {!isRead && <CircleIcon sx={{ fontSize: '12px', color: colors.primary }}/>}
          </Box>
        </Box>
      </Grid>
    ))
  )
}
