import { gql, TypedDocumentNode } from '@apollo/client'
import EstimateType, { EstimateAttributes } from '../types/estimate-type'

export const GET_ESTIMATES: TypedDocumentNode<{ estimates: EstimateType[] }> = gql`
  query GetEstimates {
    estimates {
      id
      estimateMin
      estimateMax
      availability
      details
      job {
        id
        jobCategory { name }
        city { name }
        customer {
          id
          fullName
        }
      }
      createdAt
    }
  }
`

export const GET_JOB_ESTIMATES: TypedDocumentNode<{ jobEstimates: EstimateType[] }, { jobId: string }> = gql`
  query GetJobEstimates($jobId: ID!) {
    jobEstimates(jobId: $jobId) {
      id
      estimateMin
      estimateMax
      availability
      details
      createdAt
      businessUser {
        id
        businessProfile {
          id
          name
          yearEstablished
          contactNumber
          email
          summary
          address
          socialMediaLink
        }
      }
    }
  }
`

export const GET_JOB_ESTIMATE: TypedDocumentNode<{ jobEstimate: EstimateType }, { jobId: string, estimateId: string }> = gql`
  query GetJobEstimate($jobId: ID!, $estimateId: ID!) {
    jobEstimate(jobId: $jobId, estimateId: $estimateId) {
      id
      estimateMin
      estimateMax
      availability
      details
      job {
        id
        jobCategory { name }
        city { name }
      }
      businessUser {
        id
        businessProfile {
          id
          name
          yearEstablished
          contactNumber
          email
          summary
          address
          socialMediaLink
        }
      }
    }
  }
`

export const CREATE_ESTIMATE: TypedDocumentNode<{ errors: [string], estimate: EstimateType }, { attributes: EstimateAttributes }> = gql`
  mutation CreateEstimate($attributes: EstimateAttributes!) {
    createEstimate(input: { attributes: $attributes }) {
      errors
      estimate {
        job {
          id
        }
        estimateMin
        estimateMax
        availability
        details
      }
    }
  }
`
