import React from 'react'
import { Box, Button, Divider, Grid, FormControl, FormLabel, Input, Textarea, Typography, Select, Option } from '@mui/joy'
import { fontSizes, colors, fontFamilies } from '../layouts/Theme'
import { DisplayMinMax, formatAvailability, formatTimeline } from '../utils/display-utils'
import JobType from '../types/job-type'
import { useState, useRef } from 'react'
import { EstimateAttributes, BusinessAvailability } from '../types/estimate-type'
import { Controller, useForm } from 'react-hook-form'
import NumericFormatAdapter from '../components/NumericFormatAdapter'
import { CREATE_ESTIMATE } from '../graphql/estimate-queries'
import { useMutation } from '@apollo/client'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

export default function LeadCard({ job }: { job: JobType }) {
  const { id, jobCategory, budgetMin, budgetMax, city, createdAt, timeline, description } = job
  const [showQuoteForm, setShowQuoteForm] = useState(false)
  const [estimateSubmitted, setEstimateSubmitted] = useState(false)
  const { register, handleSubmit, control } = useForm<EstimateAttributes>({
    defaultValues: {
      availability: BusinessAvailability.Immediately
    }
  })
  const cardRef = useRef<HTMLElement>(null)
  const [createEstimate, { loading: formSubmitting }] = useMutation(CREATE_ESTIMATE, {
    onCompleted: () => {
      setEstimateSubmitted(true)
      setShowQuoteForm(false)
    }
  })
  dayjs.extend(relativeTime)

  function handleGiveEstimateClick() {
    setShowQuoteForm(true)
    cardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  function onSubmit(estimate: EstimateAttributes) {
    createEstimate({ variables: { attributes: { ...estimate, jobId: job.id } } })
  }

  return (
    <Box
      ref={cardRef}
      key={id}
      sx={{
        scrollMarginTop: '20px',
        backgroundColor: '#FFF',
        borderRadius: '20px',
        mt: '20px',
        position: 'relative',
      }}
    >
      <Box sx={{ p: '20px' }}>
        <Box
          sx={{
            fontSize: fontSizes.large,
            fontWeight: 600,
          }}
        >
          {`${jobCategory.name} job in ${city.name}`}
        </Box>
        <Box
          sx={{
            fontSize: fontSizes.sm,
            color: colors.gray2,
            mt: '-2px',
          }}>
          Posted {dayjs(createdAt).fromNow()}
        </Box>
        <Box
          sx={{
            mt: '50px',
            fontSize: fontSizes.sm
          }}
        >
          <DisplayMinMax valueMin={budgetMin} valueMax={budgetMax} />
          <Box>Timeline: {formatTimeline(timeline)}</Box>
        </Box>
      </Box>
      {showQuoteForm ?
        <>
          {description &&
            <>
              <Divider />
              <Box sx={{ p: '20px' }}>
                <Typography level="title-sm" sx={{ mb: '10px' }}>Description</Typography>
                <p>{description}</p>
              </Box>
            </>}
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={1}
              sx={{
                p: '20px',
              }}
            >
              <Grid xs={12} sm={6} md={4}>
                <FormControl>
                  <FormLabel>Lower range</FormLabel>
                  <Controller
                    name="estimateMin"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Input
                        startDecorator="₱"
                        onChange={(e) => onChange(+e.target.value)}
                        slotProps={{
                          input: {
                            component: NumericFormatAdapter,
                          },
                        }}
                        placeholder="1,000"
                        autoFocus
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <FormControl>
                  <FormLabel>Higher range</FormLabel>
                  <Controller
                    name="estimateMax"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Input
                        startDecorator="₱"
                        onChange={(e) => onChange(+e.target.value)}
                        slotProps={{
                          input: {
                            component: NumericFormatAdapter,
                          },
                        }}
                        placeholder="3,000"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <FormControl>
                  <FormLabel>Timeline</FormLabel>
                  <Controller
                    name="availability"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        onChange={(_event: React.SyntheticEvent | null, newValue: BusinessAvailability | null) => onChange(newValue)}
                        defaultValue={BusinessAvailability.Immediately}
                      >
                        <Option value={BusinessAvailability.Immediately}>{formatAvailability(BusinessAvailability.Immediately)}</Option>
                        <Option value={BusinessAvailability.Weeks}>{formatAvailability(BusinessAvailability.Weeks)}</Option>
                        <Option value={BusinessAvailability.Months}>{formatAvailability(BusinessAvailability.Months)}</Option>
                        <Option value={BusinessAvailability.ScopeBased}>{formatAvailability(BusinessAvailability.ScopeBased)}</Option>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid sm={6}/>
              <Grid xs={12}>
                <FormControl>
                  <FormLabel>Details</FormLabel>
                  <Textarea {...register("details")}  placeholder="Give more details to your estimate, scope of work, and availability" minRows={6}/>
                </FormControl>
              </Grid>
              <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: '20px' }}>
                <Button onClick={() => setShowQuoteForm(false)} sx={{ mr: '10px' }} color="neutral" variant="outlined">Cancel</Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={formSubmitting}>Send Estimate</Button>
              </Grid>
            </Grid>
          </form>
        </> :
        <Box sx={{ position: 'absolute', top: '35%', right: '30px' }}>
          {estimateSubmitted ?
            <Box sx={{ mt: '20px', color: colors.gray2 }}>
              <span>Estimate submitted</span>
              <MarkEmailReadIcon sx={{ ml: '5px', color: colors.green }}/>
            </Box> :
            <Button onClick={() => handleGiveEstimateClick()}>Give Estimate</Button>}
        </Box>}
    </Box>
  )
}
