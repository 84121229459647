import { TypedDocumentNode, gql } from "@apollo/client";
import BusinessProfileType, { UpdateBusinessProfileAttributes } from "../types/business-profile-type";

export const UPDATE_BUSINESS_PROFILE: TypedDocumentNode<{ updateBusinessProfile: { errors: string[], businessProfile: BusinessProfileType }}, { attributes: UpdateBusinessProfileAttributes }> = gql`
  mutation UpdateBusinessProfile($attributes: BusinessProfileAttributes!) {
    updateBusinessProfile(input: { attributes: $attributes }) {
      errors
      businessProfile {
        id
        name
        yearEstablished
        contactNumber
        email
        address
        socialMediaLink
        completed
        summary
        logoUrl
        coveredLocations {
          id
          name
          provinceId
          provinceName
        }
        coveredCategories {
          id
          name
          slug
          description
        }
      }
    }
  }
`

export const GET_BUSINESS_PROFILE: TypedDocumentNode<{ businessProfile: BusinessProfileType }> = gql`
  query GetBusinessProfile {
    businessProfile {
      id
      name
      yearEstablished
      contactNumber
      email
      address
      socialMediaLink
      completed
      summary
      logoUrl
      coveredLocations {
        id
        name
        provinceId
        provinceName
      }
      coveredCategories {
        id
        name
        slug
        description
      }
    }
  }
`
