import BusinessUserType from "./business-user-type"
import JobType from "./job-type"

export enum BusinessAvailability {
  Immediately = "Immediately",
  Weeks = "Weeks",
  Months = "Months",
  ScopeBased = "ScopeBased",
}

export interface EstimateAttributes {
  jobId: number
  estimateMin: number
  estimateMax: number
  availability: BusinessAvailability
  details: string
}

export default interface EstimateType {
  id: string
  job: JobType
  businessUser: BusinessUserType
  estimateMin: number
  estimateMax: number
  availability: BusinessAvailability
  details: string
  createdAt: string
}
