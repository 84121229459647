//@ts-expect-error: This is needed by Rails
import React, { useEffect, useState } from 'react'
import { Grid, Box, Typography } from '@mui/joy'
import { useParams, useNavigate } from 'react-router-dom'
import JobCategoryType from '../../types/job-category-type'
import CityType from '../../types/city-type'
import { useQuery, useMutation } from '@apollo/client'
import { GET_JOB_CATEGORY } from '../../graphql/job-category-queries'
import { GET_JOBS } from '../../graphql/job-queries'
import { GET_CITY } from '../../graphql/location-queries.ts'
import { renderIcon } from '../../utils/render-utils'
import { colors } from '../../layouts/Theme'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { JobAttributes } from '../../types/job-type'
import { CREATE_JOB } from '../../graphql/job-queries'
import { GET_CURRENT_CUSTOMER } from '../../graphql/user-queries'
import JobForm from '../../components/JobForm.tsx'

export default function NewJobPage() {
  const { categorySlug, locationId } = useParams()
  const [jobCategory, setJobCategory] = useState<JobCategoryType>()
  const [city, setCity] = useState<CityType>()
  const { loading: cityLoading, data: cityData} = useQuery(GET_CITY, { variables: { id: locationId || '' } })
  const { loading: categoryLoading, data: categoryData} = useQuery(GET_JOB_CATEGORY, { variables: { slug: categorySlug || '' } })
  const [createJob, { loading: formSubmitting }] = useMutation(CREATE_JOB, {
    refetchQueries: [GET_CURRENT_CUSTOMER, { query: GET_JOBS }]
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (cityData && !cityLoading) {
      setCity(cityData.city)
    }
  }, [cityData, cityLoading])

  useEffect(() => {
    if (categoryData && !categoryLoading) {
      setJobCategory(categoryData.jobCategory)
    }
  }, [categoryData, categoryLoading])

  if (!jobCategory || !city) {
    return <div>Page not found</div>
  }

  function onSubmit(data: JobAttributes) {
    const job = { ...data, cityId: city!.id, jobCategoryId: jobCategory!.id }
    createJob({
      variables: { attributes: job },
      onCompleted: () => navigate('/customer/jobs'),
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sx={{ mb: '20px' }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ color: colors.primary }}>{renderIcon(jobCategory.slug, '45px')}</Box>
            <Typography level="h1">{jobCategory.name}</Typography>
          </Box>
          <Box sx={{ display: 'flex', ml: '10px', alignItems: 'center' }}>
            <LocationOnIcon sx={{ fontSize: '20px', mr: '5px', color: colors.gray2 }}/>
            <Typography level="body-md">{city.name}</Typography>
          </Box>
        </Grid>
      </Grid>
      <JobForm onSubmit={onSubmit} saving={formSubmitting} submitLabel="Get Estimates" />
    </>
  )
}
