//@ts-expect-error: This is needed by Rails
import React from 'react'
import { createContext, useState, useEffect, ReactNode } from 'react'
import CustomerType from '../../types/customer-type'
import { useQuery } from '@apollo/client';
import { GET_CURRENT_CUSTOMER } from '../../graphql/user-queries'

export const CustomerContext = createContext<CustomerType>({} as CustomerType)

export default function CustomerContextProvider({ children }: { children: ReactNode }) {
  const { data, loading } = useQuery(GET_CURRENT_CUSTOMER);
  const [customer, setCustomer] = useState<CustomerType>({} as CustomerType)

  useEffect(() => {
    if (!loading && data) {
      setCustomer(data.currentCustomer)
    }
  }, [data])

  return (
    <CustomerContext.Provider value={customer}>
      {children}
    </CustomerContext.Provider>
  )
}
