import { gql, TypedDocumentNode } from '@apollo/client';
import CustomerType from '../types/customer-type'
import BusinessUserType from '../types/business-user-type'

export const GET_CURRENT_CUSTOMER: TypedDocumentNode<{ currentCustomer: CustomerType }> = gql`
  query CurrentCustomer {
    currentCustomer {
      email
      fullName
      unreadNotificationsCount
      lastCity {
        id
        name
        provinceName
        provinceId
      }
    }
  }
`

export const GET_CURRENT_BUSINESS_USER: TypedDocumentNode<{ currentBusinessUser: BusinessUserType }> = gql`
  query CurrentBusinessUser {
    currentBusinessUser {
      email
      fullName
      unreadNotificationsCount
    }
  }
`
