//@ts-expect-error: This is needed by Rails
import React, { ChangeEvent, useContext, useState } from 'react'
import Typography from '@mui/joy/Typography'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_BUSINESS_PROFILE, UPDATE_BUSINESS_PROFILE } from '../../graphql/business-profile-queries'
import { Box, Button, Card, Divider, FormControl, FormLabel, Grid, Input, Textarea } from '@mui/joy'
import LocationAutocomplete from '../../components/LocationAutocomplete'
import JobCategoryAutocomplete from '../../components/JobCategoryAutocomplete'
import { UpdateBusinessProfileAttributes } from '../../types/business-profile-type'
import { useForm, Controller } from 'react-hook-form'
import CityType from '../../types/city-type'
import JobCategoryType from '../../types/job-category-type'
import { useNavigate } from 'react-router-dom'
import { GET_LEADS } from '../../graphql/job-queries'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import AddProjectModal from '../../components/AddProjectModal'
import { GET_PROJECTS } from '../../graphql/project-queries'
import { colors } from '../../layouts/Theme'

interface UpdateBusinessProfileForm extends UpdateBusinessProfileAttributes {
  coveredLocations: CityType[]
  coveredCategories: JobCategoryType[]
}

export default function ProfilePage() {
  const navigate = useNavigate()
  const [openProjectModal, setOpenProjectModal] = useState(false)
  const [updateBusinessProfile, { loading }] = useMutation(UPDATE_BUSINESS_PROFILE)
  const { data: { projects } } = useSuspenseQuery(GET_PROJECTS)
  const { showAlert, environment } = useContext(LayoutContext)
  const {
    data: {
      businessProfile: {
        completed,
        name,
        yearEstablished,
        summary,
        address,
        contactNumber,
        email,
        socialMediaLink,
        coveredLocations,
        coveredCategories,
        logoUrl,
      }
    }
  } = useSuspenseQuery(GET_BUSINESS_PROFILE)

  const [logoPreview, setLogoPreview] = useState(logoUrl)

  const { register, handleSubmit, control, setValue } = useForm<UpdateBusinessProfileForm>({
    defaultValues: {
      name,
      yearEstablished,
      summary,
      address,
      contactNumber,
      email,
      socialMediaLink,
      coveredLocations,
      coveredCategories,
    }
  })

  function cleanAttributes(attributes: UpdateBusinessProfileForm) {
    const cleanedAttrs = { ...attributes }
    cleanedAttrs.coveredCategoryIds = attributes.coveredCategories?.map(cat => cat.id) || []
    cleanedAttrs.coveredLocationIds = attributes?.coveredLocations?.map(loc => loc.id) || []

    delete cleanedAttrs.coveredCategories
    delete cleanedAttrs.coveredLocations

    return cleanedAttrs as UpdateBusinessProfileAttributes
  }

  function onSubmit(attributes: UpdateBusinessProfileForm) {
    updateBusinessProfile({
      variables: { attributes: cleanAttributes(attributes) },
      refetchQueries: [{ query: GET_LEADS }],
      onCompleted: ({ updateBusinessProfile: { businessProfile }}) => {
        if (businessProfile.completed && environment === 'development') {
          navigate('/business/leads')
        } else {
          showAlert('Your profile has been updated')
        }
      }
    })
  }

  function handleLogoChange(e: ChangeEvent<HTMLInputElement>) {
    // TODO: Move this in a centralized place
    const MAX_FILE_SIZE = 5 * 1024 * 1024
    const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/gif']
    const errors: string[] = []

    const selectedFile = e.target.files?.[0]

    if (!selectedFile) {
      return
    } else if (selectedFile.size > MAX_FILE_SIZE) {
      errors.push(`${selectedFile.name} exceeds the maximum size of 5MB`)
    } else if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      errors.push(`${selectedFile.name} is not a supported image type. Supported image types are: .jpeg, .png, and .gif`)
    }

    if (errors.length === 0) {
      setValue('logo', selectedFile)
      setLogoPreview(URL.createObjectURL(selectedFile))
    } else {
      showAlert(errors.join("\n"), 'danger')
    }
  }

  return (
    <Grid container sx={{ mb: '20px' }}>
      <Grid xs={12} md={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography level="h1">{completed ? 'Profile' : 'Complete Your Profile'}</Typography>
      </Grid>
      <Grid xs={12} md={9} sx={{ mt: '20px' }}>
        <Card size="lg" variant="outlined">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid xs={12} md={6}>
              <Typography sx={{ mb: '10px' }} level="h3">Basic Info</Typography>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <Button variant="plain" component="label" sx={{ pl: 0, bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }}>
                  <Box sx={{ width: '75px', height: '75px', backgroundColor: colors.background, borderRadius: '50%' }}>
                    {logoPreview && <img src={logoPreview} style={{ objectFit: 'cover', width: '75px', height: '75px', borderRadius: '50%' }} />}
                  </Box>
                  <input
                    style={{
                      height: '1px',
                      width: '1px',
                      clip: 'rect(0 0 0 0)',
                      clipPath: 'inset(50%)',
                      overflow: 'hidden',
                      position: 'absolute',
                      bottom: 0,
                      left: 0
                    }}
                    type="file"
                    onChange={handleLogoChange}
                  />
                </Button>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel required>Business Name</FormLabel>
                  <Input autoFocus {...register("name")} placeholder="Business name" required />
                </FormControl>
                <FormControl sx={{ mt: '15px' }}>
                  <FormLabel required>Year Established</FormLabel>
                  <Input {...register("yearEstablished")} placeholder="Year Established" required />
                </FormControl>
                <FormControl sx={{ mt: '15px' }}>
                  <FormLabel required>Summary</FormLabel>
                  <Textarea {...register("summary")} minRows={5} required placeholder="Provide a short summary of your business offerings" />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel required>Address</FormLabel>
                  <Input {...register("address")} required placeholder="Address" />
                </FormControl>
                <FormControl sx={{ mt: '15px' }}>
                  <FormLabel required>Contact number</FormLabel>
                  <Input {...register("contactNumber")} required placeholder="Contact number" />
                </FormControl>
                <FormControl sx={{ mt: '20px' }}>
                  <FormLabel required>Email</FormLabel>
                  <Input {...register("email")} required placeholder="Email" />
                </FormControl>
                <FormControl sx={{ mt: '25px' }}>
                  <FormLabel required>Social Media Account</FormLabel>
                  <Input {...register("socialMediaLink")} required placeholder="Social Media Page or Account" />
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{ mt: '20px', mb: '10px' }} inset="context" />
            {environment === 'development' &&
              <>
                <Grid xs={12}>
                  <AddProjectModal open={openProjectModal} setOpen={setOpenProjectModal} />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ mb: '10px' }} level="h3">Projects</Typography>
                    <Button color="neutral" variant="outlined" onClick={() => setOpenProjectModal(true)}>Add Project</Button>
                  </Box>
                </Grid>
                {!!projects.length &&
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {projects.map(({ id, name, description, images }) => (
                      <Grid xs={12} md={6} key={id}>
                        <Card sx={{ gap: 2, display: 'flex', flexDirection: 'row', height: '140px' }}>
                          <Box sx={{ 'img': { objectFit: 'cover', width: '100px', height: '100px', borderRadius: '8px' }}}>
                            <img src={images?.[0]?.url} />
                          </Box>
                          <Box sx={{ overflow: 'hidden' }}>
                            <Typography level="title-lg">{name}</Typography>
                            <Typography level="body-sm">{description}</Typography>
                          </Box>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>}
                {projects.length === 0 && <Typography level="body-sm">You have not added any projects yet</Typography>}
                <Divider sx={{ mt: '20px', mb: '10px' }} inset="context" />
              </>}
            <Grid xs={12} md={6}>
              <Typography sx={{ mb: '10px' }} level="h3">Coverage</Typography>
            </Grid>
            <Grid container>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Locations Covered</FormLabel>
                  <Controller
                    name="coveredLocations"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <LocationAutocomplete
                        autoFocus={false}
                        multiple={true}
                        value={value}
                        onValueChange={onChange}
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ mt: '10px' }}>
                  <FormLabel>Job Categories</FormLabel>
                  <Controller
                    name="coveredCategories"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <JobCategoryAutocomplete
                        autoFocus={false}
                        multiple={true}
                        value={value}
                        onValueChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
              </Grid>
            </Grid>
            <Divider sx={{ my: '20px' }} inset="context" />
            <Grid xs={12} >
              <Button type="submit" disabled={loading}>{loading ? 'Saving': 'Save Profile'}</Button>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  )
}
