import JobCategoryType from './job-category-type'
import CityType from './city-type'
import EstimateType from './estimate-type'
import CustomerType from './customer-type'
import ImageType from './image-type'

// TODO: Make enum values capitalized
export enum JobTimeline {
  asap = "asap",
  inWeeks = "in-weeks",
  inMonths = "in-months",
  inHalfYear = "in-half-year",
}

export interface JobAttributes {
  jobCategoryId: string
  cityId: string
  budgetMin: number | null
  budgetMax: number | null
  timeline: JobTimeline
  description: string
  fileList?: FileList
}

export interface UpdateJobAttributes {
  budgetMin?: number | null
  budgetMax?: number | null
  timeline?: JobTimeline
  description?: string
  status?: JobType['status']
  winningEstimateId?: string | null
  bumpedAt?: string
}

export default interface JobType {
  id: string
  customer: CustomerType
  status: 'active' | 'archived' | 'auto_archived' | 'completed'
  estimates: EstimateType[]
  jobCategoryId: string
  jobCategory: JobCategoryType
  winningEstimate: EstimateType
  cityId: string
  city: CityType
  budgetMin?: number
  budgetMax?: number
  timeline: JobTimeline
  description: string
  bumpedAt: string
  createdAt: string
  images: ImageType[]
}
