import { TypedDocumentNode, gql } from '@apollo/client'
import Notification from '../types/notification-type'

export const MARK_NOTIFICATION_AS_READ: TypedDocumentNode<{ errors: string[], notification: Notification }, { id: string }> = gql`
  mutation MarkNotificationAsRead($id: ID!) {
    markNotificationAsRead(input: { id: $id }) {
      notification {
        id
        subject
        path
        isRead
        createdAt
      }
      errors
    }
  }
`

export const GET_BUSINESS_NOTIFICATIONS: TypedDocumentNode<{ businessUserNotifications: Notification[] }> = gql`
  query GetBusinessNotifications {
    businessUserNotifications {
      id
      subject
      path
      isRead
      createdAt
    }
  }
`

export const GET_CUSTOMER_NOTIFICATIONS: TypedDocumentNode<{ customerNotifications: Notification[] }> = gql`
  query GetCustomerNotifications {
    customerNotifications {
      id
      subject
      path
      isRead
      createdAt
    }
  }
`
