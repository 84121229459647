//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Grid } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_LEAD } from '../../graphql/job-queries'

export default function LeadPage() {
  const { jobId } = useParams()
  const { data } = useSuspenseQuery(GET_LEAD, { variables: { id: jobId! }})

  return (
    <Grid container>
      {data.job.id}
    </Grid>
  )
}
