//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Grid, Typography } from '@mui/joy'
import { GET_CUSTOMER_NOTIFICATIONS } from '../../graphql/notification-queries'
import { useSuspenseQuery } from '@apollo/client'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsList from '../../components/NotificationsList'

export default function CustomerNotificationsPage() {
  const { data } = useSuspenseQuery(GET_CUSTOMER_NOTIFICATIONS)

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mt: '15px', mb: '20px', display: 'flex', alignItems: 'center' }}>
          <NotificationsIcon sx={{ fontSize: '28px', mr: '3px' }} />
          Notifications
        </Typography>
      </Grid>
      <NotificationsList notifications={data.customerNotifications} type="customer" />
    </Grid>
  )
}
