//@ts-expect-error: This is needed by Rails
import React, { useContext } from "react"
import ListItem from '@mui/joy/ListItem'
import List from '@mui/joy/List'
import ListItemButton from '@mui/joy/ListItemButton'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import RateReviewIcon from '@mui/icons-material/RateReview'
import { useNavigate } from 'react-router-dom'
import { isNavActive } from '../../utils/nav-utils'
import ListDivider from '@mui/joy/ListDivider'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Sidebar, { listStyle } from "../Sidebar"
import { BusinessUserContext } from "./BusinessUserContextProvider"
import { LayoutContext } from "../LayoutContextProvider"
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationBadge from "../../components/NotificationBadge"

async function signOut() {
  await fetch('/api/v1/business_user_sessions', {
    method: "DELETE"
  })

  window.location.replace('/business_login')
}

export default function BusinessSidebar() {
  const navigate = useNavigate()
  const user = useContext(BusinessUserContext)
  const { environment } = useContext(LayoutContext)

  return (
    <Sidebar>
      <List sx={{ mt: '30px', ...listStyle }}>
        {environment === 'development' &&
          <>
            <ListItem>
              <ListItemButton onClick={() => navigate('/business/leads')} selected={isNavActive('/business/leads')}>
                <PersonSearchIcon />
                Leads
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => navigate('/business/estimates')} selected={isNavActive('/business/estimates')}>
                <RequestQuoteIcon />
                Estimates
              </ListItemButton>
            </ListItem>
          </>}
        <ListItem>
          <ListItemButton onClick={() => navigate('/business/profile')} selected={isNavActive('/business/profile')}>
            <RateReviewIcon/>
            Profile
          </ListItemButton>
        </ListItem>
        {environment === 'development' &&
          <ListItem>
            <ListItemButton onClick={() => navigate('/business/notifications')} selected={isNavActive('/business/notifications')}>
              <NotificationsIcon />
              Notifications
              <NotificationBadge count={user.unreadNotificationsCount} />
            </ListItemButton>
          </ListItem>}
        <ListDivider sx={{ mt: '30px' }} />
        <ListItem>
          <ListItemButton>
            <AccountCircleIcon />
            {user.fullName}
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => signOut()}>
            <ExitToAppIcon />
            Sign out
          </ListItemButton>
        </ListItem>
      </List>
    </Sidebar>
  )
}
