//@ts-expect-error: This is needed by Rails
import React, { Suspense } from "react"
import Box from '@mui/joy/Box'
import { Outlet } from 'react-router-dom'
import CustomerSidebar from './Sidebar'
import { colors } from '../Theme'
import CustomerContextProvider from './CustomerContextProvider'
import LayoutContextProvider from "../LayoutContextProvider"
import Alert from "../Alert"
import Header from "../Header"

export default function CustomerLayout() {
  return (
    <LayoutContextProvider>
      <CustomerContextProvider>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <CustomerSidebar />
          <Box
            sx={{
              width: '100%',
              paddingTop: { xs: '0', sm: '10px' },
              backgroundColor: colors.background,
            }}
          >
            <Header />
            <Box sx={{ p: { xs: '20px', sm: '30px' }}}>
              <Alert />
              <Suspense>
                <Outlet />
              </Suspense>
            </Box>
          </Box>
        </Box>
      </CustomerContextProvider>
    </LayoutContextProvider>
  )
}
