//@ts-expect-error: This is needed by Rails
import React from 'react'
import { createContext, useState, useEffect, ReactNode } from 'react'
import { useQuery } from '@apollo/client';
import { GET_CURRENT_BUSINESS_USER } from '../../graphql/user-queries'
import BusinessUserType from '../../types/business-user-type'

export const BusinessUserContext = createContext<BusinessUserType>({} as BusinessUserType)

export default function BusinessUserContextProvider({ children }: { children: ReactNode }) {
  const { data, loading } = useQuery(GET_CURRENT_BUSINESS_USER)
  const [businessUser, setBusinessUser] = useState<BusinessUserType>({} as BusinessUserType)

  useEffect(() => {
    if (!loading && data) {
      setBusinessUser(data.currentBusinessUser)
    }
  }, [data])

  return (
    <BusinessUserContext.Provider value={businessUser}>
      {children}
    </BusinessUserContext.Provider>
  )
}
