//@ts-expect-error: This is needed by Rails
import React, { useState } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import { GET_JOBS } from '../../graphql/job-queries'
import JobType from '../../types/job-type'
import { useSuspenseQuery } from '@apollo/client'
import Box from '@mui/joy/Box'
import { fontSizes, colors } from '../../layouts/Theme'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import { Button } from '@mui/joy'
import MarkAsCompletedModal from '../../components/MarkAsCompletedModal'
dayjs.extend(relativeTime)

export default function JobsPage() {
  const { data } = useSuspenseQuery(GET_JOBS)
  const navigate = useNavigate()
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null)
  const [modalOpen, setModalOpen] = useState(false)

  function openModal(jobId: string) {
    setSelectedJobId(jobId)
    setModalOpen(true)
  }

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mt: '15px', display: 'flex', alignItems: 'center' }}>
          <RequestQuoteIcon sx={{ fontSize: '28px', mr: '3px' }}/>
          Jobs
        </Typography>
      </Grid>
      {selectedJobId && <MarkAsCompletedModal open={modalOpen} setOpen={setModalOpen} jobId={selectedJobId!} />}
      <Grid xs={12} sm={8} md={6}>
        {data.jobs.map((job: JobType) => {
          return (
            <Box
              key={job.id}
              onClick={() => navigate(`/customer/job/${job.id}`)}
              sx={{
                backgroundColor: '#FFF',
                padding: '20px',
                borderRadius: '20px',
                mt: '20px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Box
                sx={{
                  fontSize: fontSizes.large,
                  fontWeight: 600,
                }}
              >
                {`${job.jobCategory.name} job in ${job.city.name}`}
              </Box>
              <Box
                sx={{
                  fontSize: fontSizes.sm,
                  color: colors.gray2,
                }}>
                Posted {dayjs(job.createdAt).fromNow()}
              </Box>
              <Box
                sx={{
                  display: 'inline-block',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                  mt: 1,
                  mb: 2,
                  borderRadius: '20px',
                  letterSpacing: '1px',
                  padding: '4px 8px',
                  border: `1px solid ${job.status === 'active' ? colors.primary : colors.gray2}`,
                  color: job.status === 'active' ? colors.primary : colors.gray2,
                }}
              >
                {job.status}
              </Box>
              {!!job.images.length &&
                <Box sx={{ mt: 1 }}>
                  {job.images.map(({ id, url }) => {
                    return <img key={id} src={url} width="100px" style={{ maxHeight: '100px', marginRight: '5px' }} />
                  })}
                </Box>}
              {job.status === 'active' &&
                <Box sx={{ mt: 1 }}>
                  <Button onClick={(e) => { openModal(job.id); e.stopPropagation()}}>
                    Mark as Completed
                  </Button>
                </Box>}
            </Box>
          )
        })}
      </Grid>
    </Grid>
  )
}
