import { gql, TypedDocumentNode } from '@apollo/client'
import JobType, { JobAttributes, UpdateJobAttributes } from '../types/job-type'

export const GET_JOB: TypedDocumentNode<{ job: JobType }, { id: string }> = gql`
  query GetJob($id: ID!) {
    job(id: $id) {
      id
      jobCategory {
        id
        name
        slug
      }
      city {
        id
        name
      }
      budgetMin
      budgetMax
      timeline
      description
      status
      images {
        id
        url
        thumbnail
        width
        height
      }
      estimates {
        id
        estimateMin
        estimateMax
        availability
        details
        businessUser {
          businessProfile {
            name
            yearEstablished
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const CREATE_JOB: TypedDocumentNode<{ errors: string[], job: JobType }, { attributes: JobAttributes }> = gql`
  mutation CreateJob($attributes: JobAttributes!) {
    createJob(input: { attributes: $attributes }) {
      errors
      job {
        id
        jobCategory {
          id
          name
        }
        city {
          id
          name
        }
        budgetMin
        budgetMax
        timeline
        description
        status
        bumpedAt
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_JOBS: TypedDocumentNode<{ jobs: JobType[] }> = gql`
  query GetJobs {
    jobs {
      id
      jobCategory {
        id
        name
      }
      city {
        id
        name
      }
      images {
        id
        url
        width
        height
        thumbnail
      }
      status
      budgetMin
      budgetMax
      timeline
      description
      bumpedAt
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_JOB: TypedDocumentNode<{ errors: string[], job: JobType }, { id: string, attributes: UpdateJobAttributes }> = gql`
  mutation UpdateJob($id: ID!, $attributes: UpdateJobAttributes!) {
    updateJob(input: { id: $id, attributes: $attributes }) {
      errors
      job {
        id
        budgetMin
        budgetMax
        timeline
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_LEAD: TypedDocumentNode<{ job: JobType }, { id: string }> = gql`
  query GetLead($id: ID!) {
    job(id: $id) {
      id
      jobCategory {
        id
        name
        slug
      }
      city {
        id
        name
      }
      budgetMin
      budgetMax
      timeline
      description
      status
      images {
        id
        url
        thumbnail
        width
        height
      }
      createdAt
    }
  }
`

export const GET_LEADS: TypedDocumentNode<{ leads: JobType[] }> = gql`
  query GetLeads {
    leads {
      id
      jobCategory {
        id
        name
      }
      city {
        id
        name
      }
      budgetMin
      budgetMax
      timeline
      description
      createdAt
      updatedAt
    }
  }
`
