//@ts-expect-error: This is needed by Rails
import React, { useContext } from 'react'
import ListItem from '@mui/joy/ListItem'
import List from '@mui/joy/List'
import ListItemButton from '@mui/joy/ListItemButton'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useNavigate } from 'react-router-dom'
import { isNavActive } from '../../utils/nav-utils'
import Sidebar, { listStyle } from "../Sidebar"
import ListDivider from '@mui/joy/ListDivider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CustomerContext } from '../../layouts/customer/CustomerContextProvider'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationBadge from '../../components/NotificationBadge'

async function signOut() {
  await fetch('/api/v1/customer_sessions', {
    method: "DELETE"
  })

  window.location.replace('/')
}

export default function CustomerSidebar() {
  const user = useContext(CustomerContext)
  const navigate = useNavigate()

  return (
    <Sidebar>
      <List sx={{ mt: '30px', ...listStyle }}>
        <ListItem>
          <ListItemButton onClick={() => navigate('/customer/get-estimates')} selected={isNavActive('/customer/get-estimates')}>
            <PersonSearchIcon />
            Get Estimates
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => navigate('/customer/jobs')} selected={isNavActive('/customer/jobs')}>
            <RequestQuoteIcon />
            Jobs
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => navigate('/customer/notifications')} selected={isNavActive('/customer/notifications')}>
            <NotificationsIcon />
            Notifications
            <NotificationBadge count={user.unreadNotificationsCount} />
          </ListItemButton>
        </ListItem>
        <ListDivider sx={{ mt: '30px' }} />
        <ListItem>
          <ListItemButton>
            <AccountCircleIcon />
            {user?.fullName}
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => signOut()}>
            <ExitToAppIcon />
            Sign out
          </ListItemButton>
        </ListItem>
      </List>
    </Sidebar>
  )
}
