//@ts-expect-error: This is needed by Rails
import React from 'react'
import { JobTimeline } from '../types/job-type'
import { NumericFormat } from 'react-number-format'
import { BusinessAvailability } from '../types/estimate-type'

export function DisplayMinMax({ valueMin, valueMax }: { valueMin: number | undefined, valueMax: number | undefined }) {
  if (valueMin && valueMax) {
    return (
      <>
        <NumericFormat thousandSeparator="," displayType="text" prefix="₱" value={valueMin} /> – <NumericFormat thousandSeparator="," displayType="text" prefix="₱" value={valueMax} />
      </>
    )
  } else if (valueMin) {
    return <NumericFormat thousandSeparator="," displayType="text" prefix="₱" value={valueMin} />
  } else {
    return <></>
  }
}

const timelineMap = {
  [JobTimeline.asap]: "As soon as possible",
  [JobTimeline.inWeeks]: "1-3 weeks from now",
  [JobTimeline.inMonths]: "1-3 months from now",
  [JobTimeline.inHalfYear]: "6-12 months from now",
}

export function formatTimeline(timeline: JobTimeline) {
  return timelineMap[timeline]
}

const availabilityMap = {
  [BusinessAvailability.Immediately]: "Immediately",
  [BusinessAvailability.Weeks]: "1 to 4 weeks",
  [BusinessAvailability.Months]: "2 to 6 months",
  [BusinessAvailability.ScopeBased]: "Depends on work scope",
}

export function formatAvailability(availability: BusinessAvailability) {
  return availabilityMap[availability]
}
