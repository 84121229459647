//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box, Breadcrumbs, Card, Grid, Link } from '@mui/joy'
import { Link as RouterLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useSuspenseQuery } from '@apollo/client'
import { GET_JOB_ESTIMATE } from '../../graphql/estimate-queries'
import { Typography } from '@mui/joy'
import { DisplayMinMax, formatAvailability } from '../../utils/display-utils'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ShareIcon from '@mui/icons-material/Share'
import EmailIcon from '@mui/icons-material/Email'
import { colors } from '../../layouts/Theme'

export default function ViewEstimatePage() {
  const { jobId, estimateId } = useParams()
  const { data: { jobEstimate: {
    job: { jobCategory: { name: jobCategoryName }, city: { name: cityName } },
    estimateMin,
    estimateMax,
    availability,
    details,
    businessUser: { businessProfile },
  } } } = useSuspenseQuery(GET_JOB_ESTIMATE, { variables: { jobId: jobId!, estimateId: estimateId! } })
  const estimateTitle = `Estimate from ${businessProfile.name}`
  const jobTitle = `${jobCategoryName} job in ${cityName}`

  return (
    <Grid container>
      <Grid xs={12}>
        <Breadcrumbs sx={{ mb: '20px' }}>
          <RouterLink to="/customer/jobs">Jobs</RouterLink>
          <RouterLink to={`/customer/job/${jobId}`}>{jobTitle}</RouterLink>
          <Typography>{estimateTitle}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid xs={12}>
        <Typography level="h2">{estimateTitle}</Typography>
      </Grid>
      <Grid xs={12} sm={9} md={7}>
        <Card size="lg" variant="outlined" sx={{ mt: '20px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
            <Box>
              <Typography level="title-md" sx={{ mr: '10px', display: 'inline-block' }}>Estimate:</Typography>
              <DisplayMinMax valueMin={estimateMin} valueMax={estimateMax}/>
            </Box>
            <Box>
              <Typography level="title-md" sx={{ mr: '10px', display: 'inline-block' }}>Availability:</Typography>
              {formatAvailability(availability)}
            </Box>
          </Box>
          <Box sx={{ mt: '10px' }}>
            {details || <Typography level="body-md" color="neutral">No additional details provided</Typography>}
          </Box>
        </Card>
      </Grid>
      <Grid xs={12}>
        <Typography level="h3" sx={{ mt: '50px' }}>Business Profile</Typography>
      </Grid>
      <Grid xs={12} sm={9} md={7}>
        <Card size="lg" variant="outlined" sx={{ mt: '20px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography level="h4">{businessProfile.name}</Typography>
            <Typography level="body-md" color="neutral">Established {businessProfile.yearEstablished}</Typography>
            <Typography level="body-md" sx={{ mt: '5px' }}>{businessProfile.address}</Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <PhoneIphoneIcon sx={{ color: colors.primary, mr: '7px' }}/><Link level="body-md" href={`tel:${businessProfile.contactNumber}`} >{businessProfile.contactNumber}</Link>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: '10px' }}>
              <ShareIcon sx={{ color: colors.primary, mr: '7px' }}/><Typography level="body-md">{businessProfile.socialMediaLink}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: '10px' }}>
              <EmailIcon sx={{ color: colors.primary, mr: '7px' }}/><Typography level="body-md">{businessProfile.email}</Typography>
            </Box>
          </Box>
          <Box>
            {businessProfile.summary}
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}
