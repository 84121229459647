//@ts-expect-error: This is needed by Rails
import React, { useContext } from 'react'
import { LayoutContext } from './LayoutContextProvider'
import { Snackbar } from '@mui/joy'

export default function Alert() {
  const { openAlert, setOpenAlert, alertMessage, alertType } = useContext(LayoutContext)

  return (
    <Snackbar
      open={openAlert}
      onClose={() => setOpenAlert(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      color={alertType}
      autoHideDuration={5000}
    >
      {alertMessage}
    </Snackbar>
  )
}
