//@ts-expect-error: This is needed by Rails
import React, { useState } from 'react'
import Typography from '@mui/joy/Typography'
import { useSuspenseQuery } from '@apollo/client'
import { GET_ESTIMATES } from '../../graphql/estimate-queries'
import { Box, Grid, Drawer, ModalClose } from '@mui/joy'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'

interface EstimateRow {
  id: string
  customerName: string
  category: string
  cityName: string
  estimate: string
  submittedDate: string
  details: string
}

export default function EstimatesPage() {
  const { data: { estimates } } = useSuspenseQuery(GET_ESTIMATES)
  dayjs.extend(relativeTime)

  const [selectedRow, setSelectedRow] = useState<EstimateRow | null>(null)

  const rows: EstimateRow[] = estimates.map((estimate) => {
    const {
      id, estimateMin, estimateMax, createdAt, details,
      job: { customer, jobCategory: { name: category }, city: { name: cityName } }
    } = estimate

    return {
      id,
      customerName: customer.fullName,
      category,
      cityName,
      estimate: `₱${estimateMin}–₱${estimateMax}`,
      submittedDate: dayjs(createdAt).fromNow(),
      details,
    }
  })

  const columns: GridColDef[] = [
    { field: 'customerName', headerName: 'Customer Name', width: 150 },
    { field: 'category', headerName: 'Category', width: 200 },
    { field: 'cityName', headerName: 'Location', width: 200 },
    { field: 'estimate', headerName: 'Your Estimate', width: 200 },
    { field: 'submittedDate', headerName: 'Date Sent', width: 150 },
  ]

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: '20px' }}>Estimates</Typography>
      </Grid>
      <Grid xs={12}>
        <Drawer open={!!selectedRow} onClose={() => setSelectedRow(null)} anchor="right">
          <ModalClose />
          {selectedRow &&
            <Box sx={{ p: '20px' }}>
              <Typography level="h2">{selectedRow.category} for {selectedRow.customerName}</Typography>
              <Box>{selectedRow.cityName}</Box>
              <Box>{selectedRow.submittedDate}</Box>
              <Box>{selectedRow.estimate}</Box>
              <Typography level="body-md">{selectedRow.details}</Typography>
            </Box>}
        </Drawer>
        <DataGrid
          rowSelection={false}
          hideFooter
          onRowClick={(params: GridRowParams) => {
            setSelectedRow(params.row)
          }}
          sx={{
            '--DataGrid-containerBackground': 'transparent',
            '--unstable_DataGrid-headWeight': '700',
            '.MuiDataGrid-columnHeaderTitleContainer': { px: '10px' },
            '.MuiDataGrid-cell': { px: '20px' },
            '.MuiDataGrid-row:hover': { cursor: 'pointer' },
            backgroundColor: '#FFF',
            borderRadius: '10px'
          }}
          rows={rows}
          columns={columns}
        />
      </Grid>
    </Grid>
  )
}
