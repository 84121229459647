//@ts-expect-error: This is needed by Rails
import React, { useContext, useEffect, useState } from 'react'
import { Grid, Typography, List, ListItem, ListDivider, Box, Avatar, Breadcrumbs, IconButton, Button } from '@mui/joy'
import { GET_JOB, GET_JOBS, UPDATE_JOB } from '../../graphql/job-queries'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { colors } from '../../layouts/Theme'
import { ListItemButton } from '@mui/material'
import { DisplayMinMax, formatAvailability, formatTimeline } from '../../utils/display-utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { renderIcon } from '../../utils/render-utils'
dayjs.extend(relativeTime)
import EditIcon from '@mui/icons-material/Edit'
import JobForm from '../../components/JobForm'
import { JobTimeline, UpdateJobAttributes } from '../../types/job-type'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import PhotoSwipeLightbox from 'photoswipe/lightbox'

export default function JobPage() {
  const navigate = useNavigate()
  const { jobId } = useParams()
  const { data: { job } } = useSuspenseQuery(GET_JOB, { variables: { id: jobId || '' } })
  const { jobCategory, city, estimates, budgetMin, budgetMax, timeline, description, images, createdAt } = job
  const jobTitle = `${jobCategory.name} job in ${city.name}`
  const [editing, setEditing] = useState(false)
  const { showAlert } = useContext(LayoutContext)

  const [updateJob, { loading: saving }] = useMutation(UPDATE_JOB, {
    refetchQueries: [{ query: GET_JOBS }]
  })

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#jobImages',
      children: 'a',
      pswpModule: () => import('photoswipe')
    })
    lightbox.init()

    return () => {
      lightbox.destroy()
    }
  }, [])

  function onSubmit(attributes: UpdateJobAttributes) {
    updateJob({
      variables: { id: jobId!, attributes },
      onCompleted: () => {
        showAlert("Job updated successfully")
        navigate('/customer/jobs')
      },
    })
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <Breadcrumbs sx={{ mb: '10px' }}>
          <Link to="/customer/jobs">Jobs</Link>
          <Typography>{jobTitle}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid xs={12} sm={6} md={9}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ color: colors.primary, mr: '5px', fontSize: '35px' }}>{renderIcon(jobCategory.slug)}</Box>
            <Typography level="h2" sx={{ display: 'inline-block', mt: '5px' }}>{jobTitle}</Typography>
            {!editing && <IconButton onClick={() => setEditing(true)}><EditIcon/></IconButton>}
          </Box>
        </Box>
      </Grid>
      <Grid xs={12}>
        <Box sx={{ color: 'var(--joy-palette-neutral-500)' }}>Posted {dayjs(createdAt).fromNow()}</Box>
      </Grid>
      {editing ?
        <Grid>
          <JobForm
            onSubmit={onSubmit}
            saving={saving}
            submitLabel="Save"
            timeline={job.timeline as JobTimeline}
            budgetType={job.budgetMin ? 'withBudget' : 'withoutBudget'}
            budgetMin={job.budgetMin}
            budgetMax={job.budgetMax}
            description={job.description}
            buttonSlot={<Button color="neutral" variant="outlined" onClick={() => setEditing(false)}>Cancel</Button>}
          />
        </Grid> :
        <Grid xs={12} sm={6} md={7} sx={{ mt: '20px' }}>
          <Box sx={{ display: 'inline-block', mr: '10px' }}>
            <Typography color="neutral" sx={{ display: 'inline-block' }}>Budget:&nbsp;</Typography>
            <DisplayMinMax valueMin={budgetMin} valueMax={budgetMax} />
          </Box>
          <Box sx={{ display: 'inline-block' }}>
            <Typography color="neutral" sx={{ display: 'inline-block' }}>Timeline:&nbsp;</Typography>
            <Typography level="body-md" sx={{ display: 'inline-block' }}>{formatTimeline(timeline)}</Typography>
          </Box>
          <Typography level="body-md" sx={{ mt: '5px', whiteSpace: 'pre' }}>{description}</Typography>
          <Box id="jobImages" sx={{ display: 'flex', gap: 1, mt: '20px' }}>
            {images.map(({ id, thumbnail, url, width, height }) => {
              return (
                <a href={url} key={id} data-pswp-width={width} data-pswp-height={height} target="_blank" rel="noreferrer">
                  <img src={thumbnail} width="100px" style={{ maxHeight: '200px' }} />
                </a>
              )
            })}
          </Box>
        </Grid>}
      <Grid xs={12}>
        <Typography level="h3" sx={{ mt: '30px' }}>Estimates Received</Typography>
      </Grid>
      {estimates.length ?
        <Grid xs={12} sm={6} md={9}>
          <List
            variant="outlined"
            sx={{
              borderRadius: '20px',
              borderColor: colors.gray1,
              mt: '10px',
              p: 0,
              backgroundColor: colors.white,
              '> li:first-of-type > .MuiListItemButton-root': {
                borderRadius: '20px 20px 0 0',
                pt: '10px',
              },
              '> li:last-of-type > .MuiListItemButton-root': {
                borderRadius: '0 0 20px 20px',
                pb: '10px',
              },
              '> li:only-of-type > .MuiListItemButton-root': {
                borderRadius: '20px',
              }
            }}
          >
            {estimates.map((estimate, index) => {
              const { estimateMin, estimateMax, availability, businessUser: { businessProfile: { name } } } = estimate
              const displayDivider = estimates.length > 1 && index >= estimates.length - 1

              return (
                <>
                  {displayDivider && <ListDivider sx={{ m: 0, '--Divider-thickness': '1px', '--Divider-lineColor': colors.gray1 }} />}
                  <ListItem sx={{ p: 0 }}>
                    <ListItemButton onClick={() => navigate(`/customer/job/${jobId}/estimate/${estimate.id}`)}>
                      <Box sx={{ width: '10%' }}>
                        <Avatar>{name[0]}</Avatar>
                      </Box>
                      <Box sx={{ width: '25%' }}>{name}</Box>
                      <Box sx={{ width: '25%' }}>
                        <DisplayMinMax valueMin={estimateMin} valueMax={estimateMax} />
                      </Box>
                      <Box sx={{ width: '25%' }}>{formatAvailability(availability)}</Box>
                      <Box sx={{ width: '15%', textAlign: 'right' }}><KeyboardArrowRightIcon /></Box>
                    </ListItemButton>
                  </ListItem>
                </>
              )
            })}
          </List>
        </Grid> :
        <Box sx={{ mt: '10px' }}>There is no estimates yet.</Box>}
    </Grid>
  )
}
